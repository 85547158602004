<template>
  <div class="page-container">
    <div class="order-amount">
      <p>￥{{item.amount}}</p>
    </div>
    <div class="order-detail">
      <van-cell-group>
        <van-cell title="付款编号" :value="item.order_no" />
        <van-cell title="付款方式" :value="payType" />
        <van-cell title="项目名称" :value="item.title" />
        <van-cell title="付款方" :value="item.name" />
        <van-cell title="收款方" value="山东理工大学教育发展基金会" />
      </van-cell-group>
    </div>
    <div class="order-pay">
      <van-button v-if="paied" :url="item.return_url" size="large">{{isWechat?"支付成功,正在跳转中...":"支付成功,请回到微信继续"}}</van-button>
      <template v-else>
        <van-button v-if="payType === '支付宝'" :url="payUrl" type="primary" size="large">继续支付</van-button>
        <van-button v-if="payType === '微信支付'" @click="wechatPay" type="primary" size="large">继续支付</van-button>
      </template>
    </div>
    <sdut-banner />
    <van-overlay :show="showOverlay">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="browser-guide">
            <div class="guide-point">
              <img :src="PointImg" alt="箭头">
            </div>
            <p class="guide-step1">1. 点击右上角菜单</p>
            <div class="guide-pic">
              <img :src="GuideImg" alt="箭头">
            </div>
            <p class="guide-step2">2. 选择使用浏览器打开，完成支付</p>
            <p class="guide-step3">3. 支付成功后返回微信</p>
          </div>
          <div class="finish-pay">

            <van-button color="rgba(0,0,0,.7)" type="primary" @click="checkOrderStatus">已经完成支付</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import SdutBanner from '@/components/SdutBanner'
import PointImg from '@/assets/images/point.png'
import GuideImg from '@/assets/images/guide.png'

import { Notify } from 'vant'

import { getOrderInfo, getOrderStatus } from '@/api/donate'
import wxSdk from '@/utils/wxsdk'

export default {
  components: { SdutBanner },
  data () {
    return {
      paied: false,
      showOverlay: false,
      item: {
        order_no: null,
        name: null,
        title: null,
        type: null,
        amount: 0,
        return_url: null
      },
      payUrl: null,
      payType: null,
      PointImg: PointImg,
      GuideImg: GuideImg,
      isWechat: false
    }
  },
  mounted () {
    let ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i)) {
      this.isWechat = true
    }
    getOrderInfo(this.$route.params.order_no).then(response => {
      console.log(response)
      this.item = response
      this.payUrl = `/api/pay/${this.item.order_no}`
      this.paied = response.status
      if (this.item.type === 1) {
        this.payType = '微信支付'
      } else {
        this.payType = '支付宝'
        if (this.isWechat) {
          this.showOverlay = true
        }
      }
    })
    this.checker = setInterval(this.checkOrderStatus, 1000)
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'DonateForm') {
      to.meta.keepAlive = true
    } else {
      to.meta.keepAlive = false
    }
    next()
  },
  methods: {
    checkOrderStatus () {
      getOrderStatus(this.$route.params.order_no).then(response => {
        this.paied = response.status
        if (this.paied) {
          this.showOverlay = false
          if (this.isWechat) {
            Notify({ type: 'success', message: '支付成功' })
            clearInterval(this.checker)
            setTimeout(() => {
              window.location.href = this.item.return_url
            }, 2000)
          } else {
            Notify({ type: 'success', message: '支付成功，请返回微信继续' })
          }
        }
      })
    },
    wechatPay () {
      wxSdk.pay(this.$route.params.order_no)
    }
  }
}
</script>

<style>
.order-amount {
  height: 5rem;
}

.order-amount p {
  font-size: 3rem;
  color: red;
  text-align: center;
  margin: 0;
  margin-top: 1.5rem;
}

.browser-guide {
  height: 18rem;
}

.guide-point img {
  width: 4rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.guide-pic img {
  width: 70%;
  position: absolute;
  top: 3rem;
  margin: 3rem 15%;
}

.guide-step1,
.guide-step2 ,
.guide-step3{
  font-size: 1.4rem;
  color: #ffffff;
  margin: 0;
  position: absolute;
  left: 2rem;
}

.guide-step1 {
  top: 2.5rem;
}

.guide-step2 {
  top: 12.5rem;
}

.guide-step3 {
  top: 14.5rem;
}

.finish-pay {
  text-align: center;
}
</style>
